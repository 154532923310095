const hu = {
    msgAppName: "Woodcircle",

    actionGetStart: "Kezdjünk hozzá",
    actionDetails: "Részletek",
    actionLogin: "Belépés",
    actionRegister: "Regisztráció",
    actionLogout: "Kijelentkezés",
    actionSend: "Elküld",
    actionGoTest: "Tovább a tesztre",
    actionSeeCert: "Eredmény megtekintése",
    actionNextPill: "Tovább a következő tananyagra",
    actionNewThread: "Új probálkozás",
    actionBack: "Vissza",
    actionComment: "Megjegyzés",
    actionUpdate: "Frissítés",
    actionChangePass: "Jelszó csere",
    actionAddAnnouncement: "Megjegyzés hozzáadása",
    actionStartTest: "Teszt indítása",
    actionSubmit: "Elküld",
    actionClose: "Bezár",
    actionEdit: "Szerkeszt",
    actionDelete: "Töröl",
    actionShow: "Mutasd meg",
    actionNext: "Következő",
    actionDone: "Kész",
    actionOpenResource: "Nyilvános forrás",
    actionDownloadInfo: "Felhasználói kézikönyv letöltése",
    actionCourseCertificate: "Tanfolyami bizonyítvány",
    actionRequestCode: "Request code",

    msgWelcome: "Üdvözlünk",
    msgBackToUnit: "Vissza a tananyaghoz",
    msgExploreUnits: "Fedezd fel a WOODCircle online tanfolyamot!",
    msgExploreUnitsText: "Az ipar folyamatos digitalizációja, a big data fejlődése, az internethez és az innovatív anyagokhoz kapcsolható fa- és bútoripari termékek gyártása Európa-szerte az ágazat átalakulásához vezet, melynek nap, mint nap szemtanúi vagyunk. Az európai vállalatok olyan digitálisan kompetens faipari szakembereket keresnek, akik naprakészek az Ipar 4.0 gyakorlatát és elveit illetően. A WOODCircle tanfolyam egyedülálló továbbképzési lehetőséget kínál, amely lehetővé teszi, hogy te is a digitálisan kompetens ASZTALOS 4.0-vá válj.",
    msgLearningUnits: "Tananyagok",
    msgLatestPills: "Ajánlott fejezetek",
    msgLatestPillsText: "Fedezd fel az európai partnereink által ajánlott fejezeteket",

    msgPills: "Fejezetek",
    msgPartners: "Partnerek",
    msgNextLearning: "Következő tananyag",

    msgExamAlert: "Az összes tananyag elsajátítása után a tanulóknak ki kell tölteniük egy <b>feleletválasztós önértékelés</b> a teljesítés érdekében <b>tanúsítvány és digitális jelvény.</b> A minimum <b>eredmény a 75%</b> ami az adott tananyag teljesítéséhez szükséges.",
    msgAssignment: "Feladat",
    msgForStudents: "Menedzserek részére",
    msgForTeachers: "Tanárok részére",
    msgProgressionOptions: "Továbblépési lehetőségek",
    msgCompetencesUnitTitle: "E tananyag elsajátítása után a tanulóknak képessé vállhatnak arra, hogy:",

    msgCompetencesTitle: "E tananyag elsajátításával a hallgatóknak képesnek kell lenniük arra, hogy:",
    msgTopics: "Témakörök",
    msgHowApply: "Az ismeretek gyakorlati alkalmazása",
    msgResources: "Tananyagok",
    msgResourcesSubtitle: "Az önértékelés teszt kitöltésének megkezdése előtt olvasd el az összes felajánlot tananyagot",

    msgStartTestSubtitle: "**A tananyag összes fejezetének elsajátítása esetén**",


    msgWooLearningPlatform: "WOODCircle oktatási platform",
    msgWooLearningPlatformText: "A WOODCircle oktatási platformot azoknak a digitális innováció előmozdításában érdekelt személyeknek és intézményeknek ajánljuk, akik minőségi képzésben szeretnének részesülni.<br/><br/>A platform európai fiatalok, diákok, szakemberek, valamint szakképzési központok, szakoktatási intézmények és vállalatok is támogat, és olyan készségekkel és kompetenciákkal látja el őket, amelyeket a digitalizáció átalakuló folyamatában lévő fa- és bútoripari munkaerőpiac megkövetel. Erősen ajánljuk ezt a képzést:",

    msgStudents: "Tanulóknak",
    msgEducators: "Oktatóknak",
    msgCompanies: "Vállalatoknak és Egyesületeknek",
    msgProfessionals: "Szakmabelieknek / Munkanélkülieknek",

    msgStudentsText: "Jövőbeli asztalosok, akik a munkaerőpiacra való belépés előtt szeretnék elsajátítani a szükséges digitális készségeket, és biztosítani, hogy naprakész ismeretekkel rendelkezzenek az európai fa- és bútoriparról.",
    msgEducatorsText: "Szakképzési és képzési központok, amelyek a jövő faipari szakembereit oktatják, és érdeklődnek az innovatív és minőségi képzési tartalom iránt.",
    msgCompaniesText: "Faipari és bútoripari vállalatok, amelyek hajlandóak fejleszteni alkalmazottaik digitális kompetenciáit.",
    msgProfessionalsText: "Azok, akik konkrét szakmai készségek fejlesztésére és/vagy új karrierlehetőségek felfedezésére keresnek lehetőséget.",

    msgContact: "Kapcsolat",
    msgHome: "Home",
    msgForum: "Fórum",
    msgProfile: "Profil",

    msgAnnouncements: "Bejegyzés",
    msgAnnouncementsFinished: "Befejezett bejegyzés",

    msgThreads: "Threads",
    msgOpen: "Megnyit",
    msgClose: "Bezár",
    msgLeaveComment: "Megjegyzés hozzáadása",

    msgTeacherProfile: "Oktatói profil",
    msgUserProfile: "Felhasználói profil",
    msgUserInfo: "Felhasználói információk",

    msgRegisterText: "Regisztrálj és fedezd fel a WOODCircle online tanfolyamot!",

    msgName: "Keresztnév és vezetéknév",
    msgEmail: "E-mail",
    msgCountry: "Ország",
    msgAge: "Kor",
    msgGender: "Nem",
    msgOrganization: "Intézmény",

    msgForgetPass: "Elfelejtetted a jelszavad?",
    msgRecoveryPassword: "Recovery password",
    msgCode: "Code",

    msgPassword: "Jelszó",
    msgPassInfo: "Jelszó csere",
    msgCurrentPass: "Aktuális jelszó",
    msgNewPassword: "Új jelszó",

    msgNewAnnouncement: "Új bejegyzés",
    msgNewAnnouncementInfo: "Új bejegyzés hozzádása",
    msgAnnouncesInfo: "Bejegyzéseim",


    msgMyCertificates: "Eredményeim",
    msgMyCertificatesInfo: "Az összes eredményem listája",

    msgDatenEnd: "A dátum vége",
    msgText: "Szöveg",
    msgWriteText: "Szövegmező",

    msgTitle: "Cím",

    msgTerms: "Feltételek elfogadása",
    msgCopyright: "Copyright&copy;2023 WOODCIRCLE. Minden jog fenntartva.",

    msgProyectOwner: "A PROJEKT",
    msgFooterContact: "KAPCSOLAT",
    msgFooterNews: "HÍRLEVÉL",
    msgFooterLegal: "Jogi nyilatkozat",
    msgFooterPrivacy: "Adatvédelem",
    msgFooterCookies: "Cookie Policy",
    msgFooterEu: "The \"WOODcircle\" project is co-funded by the Erasmus+ programme of the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union Commission, who cannot be held responsible for them.",
    msgFooterGr: "Pályázati azonosító: 2022-1-IE01-KA220-VET-000086833",
    msgFooterLinkedIn: 'LINKEDIN GROUP',

    msgSendSuccess: "sikeres művelet",

    errorOblData: "Kérjük, töltsd ki az összes kötelező mezőt.",
    errorLogin: "hibás e-mail/jelszó",
    errorRegister: "",
    errorSend: "Rendszerhiba, kérjük, ellenőrizd az adataidat.",
    errorEmailFormat: "Email format error",
    errorRecovery: "Recovery password error, please check code provided",

    msgNotPass: "Ez a teszt sajnos most nem sikerült. Kérjük, olvsd el újra a tananyagot és próbáld meg újra kitölteni a tesztet.",
    msgCongratulations: "Gratulálunk!!! Átmentél ezen a teszten. A tanúsítványt a Profil szekcióban találod.",

    /* PROFILE TYPES */
    msgStudent: "Tanuló",
    msgVetProvider: "Oktató - szakképzésben",
    msgHightEducation: "Oktató - felsőoktatásban",
    msgCompany: "Vállalkozás",
    msgCompanyWorker: "Vállalati vezető",
    msgCompanyManager: "Munkavállaló",
    msgOther: "Egyéb",

    /* GENDER */
    msgFemale: "Nő",
    msgMale: "Férfi",
    msgNonBinary: "Nem-bináris",
    msgTransgender: "Transznemű",
    msgIntersex: "Interszexuális",
    msgLetme: "Egyéb",
    msgNotSay: "Nem szeretnék nyilatkozni",

    /* COUNTRIES */
    msgAfghanistan: "Afghanistan",
    msgAlbania: "Albania",
    msgAlgeria: "Algeria",
    msgAmericanSamoa: "American Samoa",
    msgAndorra: "Andorra",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antarctica",
    msgAntiguaandBarbuda: "Antigua and Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Armenia",
    msgAruba: "Aruba",
    msgAustralia: "Australia",
    msgAustria: "Austria",
    msgAzerbaijan: "Azerbaijan",
    msgBahamas: "Bahamas",
    msgBahrain: "Bahrain",
    msgBangladesh: "Bangladesh",
    msgBarbados: "Barbados",
    msgBelarus: "Belarus",
    msgBelgium: "Belgium",
    msgBelize: "Belize",
    msgBenin: "Benin",
    msgBermuda: "Bermuda",
    msgBhutan: "Bhutan",
    msgBolivia: "Bolivia",
    msgBosniaHerzegovina: "Bosnia and Herzegovina",
    msgBotswana: "Botswana",
    msgBouvetIsland: "Bouvet Island",
    msgBrazil: "Brazil",
    msgBritishIndian: "British Indian Ocean Territory",
    msgBrunei: "Brunei",
    msgBulgaria: "Bulgaria",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Cambodia",
    msgCameroon: "Cameroon",
    msgCanada: "Canada",
    msgCapeVerde: "Cape Verde",
    msgCaymanIslands: "Cayman Islands",
    msgCentralAfrican: "Central African Republic",
    msgChad: "Chad",
    msgChile: "Chile",
    msgChina: "China",
    msgChristmasIsland: "Christmas Island",
    msgCocosIslands: "Cocos (Keeling) Islands",
    msgColombia: "Colombia",
    msgComoros: "Comoros",
    msgCongo: "Congo",
    msgRepublicCongo: "The Democratic Republic of Congo",
    msgCookIslands: "Cook Islands",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Ivory Coast",
    msgCroatia: "Croatia",
    msgCuba: "Cuba",
    msgCyprus: "Cyprus",
    msgCzechRepublic: "Czech Republic",
    msgDenmark: "Denmark",
    msgDjibouti: "Djibouti",
    msgDominica: "Dominica",
    msgDominicanRepublic: "Dominican Republic",
    msgEastTimor: "East Timor",
    msgEcuador: "Ecuador",
    msgEgypt: "Egypt",
    msgEngland: "England",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Equatorial Guinea",
    msgEritrea: "Eritrea",
    msgEstonia: "Estonia",
    msgEthiopia: "Ethiopia",
    msgFalklandIslands: "Falkland Islands",
    msgFaroeIslands: "Faroe Islands",
    msgFijiIslands: "Fiji Islands",
    msgFinland: "Finland",
    msgFrance: "France",
    msgFrenchGuiana: "French Guiana",
    msgFrenchPolynesia: "French Polynesia",
    msgFrenchSouthern: "French Southern territories",
    msgGabon: "Gabon",
    msgGambia: "Gambia",
    msgGeorgia: "Georgia",
    msgGermany: "Germany",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Greece",
    msgGreenland: "Greenland",
    msgGrenada: "Grenada",
    msgGuadeloupe: "Guadeloupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernsey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea-Bissau",
    msgGuyana: "Guyana",
    msgHaiti: "Haiti",
    msgHeardMcDonald: "Heard Island and McDonald Islands",
    msgHolySee: "Holy See (Vatican City State)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Hungary",
    msgIceland: "Iceland",
    msgIndia: "India",
    msgIndonesia: "Indonesia",
    msgIran: "Iran",
    msgIraq: "Iraq",
    msgIreland: "Ireland",
    msgIsrael: "Israel",
    msgIsleMan: "Isle of Man",
    msgItaly: "Italy",
    msgJamaica: "Jamaica",
    msgJapan: "Japan",
    msgJersey: "Jersey",
    msgJordan: "Jordan",
    msgKazakhstan: "Kazakhstan",
    msgKenya: "Kenya",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwait",
    msgKyrgyzstan: "Kyrgyzstan",
    msgLaos: "Laos",
    msgLatvia: "Latvia",
    msgLebanon: "Lebanon",
    msgLesotho: "Lesotho",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Libyan Arab Jamahiriya",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Lithuania",
    msgLuxembourg: "Luxembourg",
    msgMacao: "Macao",
    msgNorthMacedonia: "North Macedonia",
    msgMadagascar: "Madagascar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malaysia",
    msgMaldives: "Maldives",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Marshall Islands",
    msgMartinique: "Martinique",
    msgMauritania: "Mauritania",
    msgMauritius: "Mauritius",
    msgMayotte: "Mayotte",
    msgMexico: "Mexico",
    msgMicronesia: "Micronesia, Federated States of",
    msgMoldova: "Moldova",
    msgMonaco: "Monaco",
    msgMongolia: "Mongolia",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Montenegro",
    msgMorocco: "Morocco",
    msgMozambique: "Mozambique",
    msgMyanmar: "Myanmar",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Netherlands",
    msgNetherlandsAntilles: "Netherlands Antilles",
    msgNewCaledonia: "New Caledonia",
    msgNewZealand: "New Zealand",
    msgNicaragua: "Nicaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Norfolk Island",
    msgNorthKorea: "North Korea",
    msgNorthernIreland: "Northern Ireland",
    msgNorthernMariana: "Northern Mariana Islands",
    msgNorway: "Norway",
    msgOman: "Oman",
    msgPakistan: "Pakistan",
    msgPalau: "Palau",
    msgPalestine: "Palestine",
    msgPanama: "Panama",
    msgPapua: "Papua New Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Peru",
    msgPhilippines: "Philippines",
    msgPitcairn: "Pitcairn",
    msgPoland: "Poland",
    msgPortugal: "Portugal",
    msgPuertoRico: "Puerto Rico",
    msgQatar: "Qatar",
    msgReunion: "Reunion",
    msgRomania: "Romania",
    msgRussianFederation: "Russian Federation",
    msgRwanda: "Rwanda",
    msgSaintHelena: "Saint Helena",
    msgSaintKitts: "Saint Kitts and Nevis",
    msgSaintLucia: "Saint Lucia",
    msgSaintPierre: "Saint Pierre and Miquelon",
    msgSaintVincent: "Saint Vincent and the Grenadines",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Sao Tome and Principe",
    msgSaudiArabia: "Saudi Arabia",
    msgScotland: "Scotland",
    msgSenegal: "Senegal",
    msgSerbia: "Serbia",
    msgSeychelles: "Seychelles",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapore",
    msgSlovakia: "Slovakia",
    msgSlovenia: "Slovenia",
    msgSolomonIslands: "Solomon Islands",
    msgSomalia: "Somalia",
    msgSouthAfrica: "South Africa",
    msgSouthSandwich: "South Georgia and the South Sandwich Islands",
    msgSouthKorea: "South Korea",
    msgSouthSudan: "South Sudan",
    msgSpain: "Spain",
    msgSriLanka: "SriLanka",
    msgSudan: "Sudan",
    msgSuriname: "Suriname",
    msgSvalbardJan: "Svalbard and Jan Mayen",
    msgSwaziland: "Swaziland",
    msgSweden: "Sweden",
    msgSwitzerland: "Switzerland",
    msgSyria: "Syria",
    msgTajikistan: "Tajikistan",
    msgTanzania: "Tanzania",
    msgThailand: "Thailand",
    msgTimorLeste: "Timor-Leste",
    msgTogo: "Togo",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinidad and Tobago",
    msgTunisia: "Tunisia",
    msgTurkey: "Turkey",
    msgTurkmenistan: "Turkmenistan",
    msgTurksCaicos: "Turks and Caicos Islands",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ukraine",
    msgUnitedArabEmirates: "United Arab Emirates",
    msgUnitedKingdom: "United Kingdom",
    msgUnitedStates: "United States",
    msgUnitedStatesMinor: "United States Minor Outlying Islands",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistan",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Virgin Islands, British",
    msgVirginIslandsUS: "Virgin Islands, U.S.",
    msgWales: "Wales",
    msgWallisFutuna: "Wallis and Futuna",
    msgWesternSahara: "Western Sahara",
    msgYemen: "Yemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabwe",

}

export default hu;