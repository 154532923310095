import React,  { useContext, Fragment } from 'react';
import { Button, Card, ContainerFlex, LanguageSelector, TextLocalized } from './styled/Styled';

import name from '../images/name.png'
import { useNavigate } from 'react-router-dom';
import { UserAction, UserContext } from './provider/UserProvider';

const HeaderItem = ({ label, action }) => <Button onClick={action} mh="4" pv="4" ph="16"
    fontSize="14px" fontColor="color-black"
    children={<TextLocalized children={label} />} />


const Header = () => {
    let { state, dispatch } = useContext(UserContext);
    let history = useNavigate();

    return <Card flex shadowOn width="12" height="64px" justify="space-between" align="center" ph="16" pv="12" color="color-white" mb="1">
        <Button flex onClick={() => history("/")} align="center" children={<img alt="logo" src={name} height="24px" />} />

        <ContainerFlex align="center">
            {
                state.login ?
                    <Fragment>
                        <HeaderItem action={() => history("/units")} label="msgLearningUnits" />
                        <HeaderItem action={() => history("/forum")} label="msgForum" />
                        <HeaderItem action={() => history("/profile")} label="msgProfile" />
                        <Button onClick={() => dispatch({ action: UserAction.logout })} pv="4" ph="16" mh="16" fontSize="14px" fontWeight="500" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" children={<TextLocalized children="actionLogout" />} />
                    </Fragment>
                    :
                    <Fragment>
                        <HeaderItem action={() => history("/login")} label="actionLogin" />
                        <Button onClick={() => history("/register")} pv="4" ph="16" mh="8" fontSize="14px" fontWeight="500" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" children={<TextLocalized children="actionRegister" />} />
                    </Fragment>
            }
            <LanguageSelector />
        </ContainerFlex>
    </Card>
};

export default Header;